import servicesService from './services.service';
import serviceEdit from './service-edit/service-edit.directive';
import serviceItem from './service-item/service-item.directive';
import services from './services/services.component';
import addService from './services-add/services-add.component';
import servicesTotal from './services-total/services-total.component';
angular.module('invoicer.services', [])
.service('servicesService', servicesService)
.directive('serviceEdit', serviceEdit)
.directive('serviceItem', serviceItem)
.component('services', services)
.component('addService', addService)
.component('servicesTotal', servicesTotal)


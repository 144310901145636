import template from "./nav-queue.tpl.html";

const myController = [
  "$state",
  "activeState",
  "statusOptionsService",
  function ($state, activeState, statusOptionsService) {
    var vm = this;
    vm.state = activeState.getState();
    //vm.loading = activeState.getLoading();

    vm.changeStage = function (newStage) {
      vm.loading = activeState.getLoading();
      if (!vm.loading) {
        $state.go("invoices.list", {
          status: vm.state.status,
          stage: newStage,
        });
      }
    };

    vm.$onInit = function () {
      statusOptionsService.getOptions().then(function (resp) {
        vm.stages = resp;
      });
    };

     vm.refreshPage = () => {
       window.location.reload();
     };
  },
];

export default {
  controllerAs: "vm",
  controller: myController,
  template,
};

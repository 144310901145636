import template from './invoice-list-item.tpl.html';


const myController = function (  ) {
    var vm = this;
    vm.$onInit = function(){
        
    }
};    


export default {        
    bindings: { item: '<' },
    controllerAs: 'vm',
    controller: myController,
    template
};
const filesService = ['$rootScope', '$http', 'Upload',
                        function ($rootScope, $http, Upload) {
    var myService = this;        
    myService.api = {
        get: function get(invoiceId) {
            return $http( {
                    method: "get",
                    url: `${$rootScope.api}/api/invoice/documents/${invoiceId}`,
                }).then(function (resp) {                              
                    return resp.data;                              
                }).catch(function(err) {
                    throw("Error retrieving documents");
                });
        },        
        stream: function stream( invoiceId, documentName ) {
            return $http( {
                    method: "get",
                    url: `${$rootScope.api}/api/invoice/document/stream/${invoiceId}/${documentName}`,
                }).then(function (resp) {
                    return resp;                          
                }).catch(function(err) {
                    throw("Error downloading document");
                });                    
        }, 
        getTempUrl: function getTempUrl( invoiceId, documentName ) {
            return $http( {
                    method: "get",
                    url: `${$rootScope.api}/api/invoice/document/url/${invoiceId}/${documentName}/60`,
                }).then(function (resp) {                        
                    window.open(resp.data.url);
                    return false;
                }).catch(function(err) {
                    throw("Error downloading document");
                });                    
        },
        add: function post(invoiceId, file) {
            return Upload.upload({
                        method: "post",
                        url: `${$rootScope.api}/api/invoice/document/${invoiceId}`,
                        data:  { fileName: file } 
                }).then(function (resp) {
                    return resp.data;
                }).catch(function(err) {
                    throw("Error adding uploading document");
                });
        },                    
        delete: function set(invoiceId, documentName) {
            return $http( {
                    method: "delete",
                    url: `${$rootScope.api}/api/invoice/document/${invoiceId}/${documentName}`,
                    }).then(function (resp) {
                    return resp;                       
                    }).catch(function(err) {                     
                    throw("Error deleting note");
                    });    
        }                                                            
    };    
    
    
    return myService;
}];

export default filesService;
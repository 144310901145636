import template from './requestor-edit.tpl.html';

const myController = ['icToastService', 'invoicesService', function (icToastService, invoicesService) {
    var vm = this;


    vm.$onInit = function () {
        // // Get the invoice data
        invoicesService.getOne(vm.invoiceId).then(function (data) {
            vm.invoice = data;
        }).catch(function (err) {
            icToastService.showSimpleToast(err, true);
        });
    
    }
}];

const requestorEdit = {
    bindings: { invoiceId: '<', invoice: '<' },
    controllerAs: 'vm',
    controller: myController,
    template
};

export default requestorEdit;
const myController = [
  "$rootScope",
  "$state",
  "$mdToast",
  "icToastService",
  "oneClickService",
  "invoicesService",
  "$log",
  function (
    $rootScope,
    $state,
    $mdToast,
    icToastService,
    oneClickService,
    invoicesService,
    $log
  ) {
    var vm = this;
    vm.secondaryOpen = false;

    function showInvoiceNavToast(invoiceId) {
      //add error handling
      var toastMsg="";
      if (!invoiceId){
        toastMsg = "An error occurs, please report to developer."
      }
      else{
        toastMsg = "New Invoice Created";
      }
      console.log(invoiceId);
      var toast = $mdToast
        .simple()
        .textContent(toastMsg)
        // .actionKey('z')
        // .actionHint('Press the Control-"z" key combination to ')
        .action(invoiceId)
        .dismissHint("Hit the Escape key to dismiss this toast.")
        .highlightAction(true)
        .highlightClass("md-primary")
        .highlightAction(true)
        .position("top right")
        .hideDelay(0);

      $mdToast
        .show(toast)
        .then(function (response) {
          if (response === "ok") {
            $state.go("invoices.detail.display", { invoiceId: invoiceId });
          } else {
            $log.log("Toast dismissed.");
          }
        })
        .catch(function () {
          $log.log(
            "Toast failed or was forced to close early by another toast."
          );
        });
    }

    vm.oneClickAction = function (oneClickAction) {
      var myData = {
        filters: {
          invoiceId: vm.invoice.id,
          templateID: vm.invoice.template_id,
        },
      };

      myData.action = "oneClick";
      myData.oneClick = oneClickAction;

      if (oneClickAction === "createPDF") {
        var url = `${$rootScope.api}/api/pdf/download/${vm.invoice.invoice_guid}`;
        window.open(url);
        return false;
      }
      if (oneClickAction === "createSitePDF") {
        icToastService.showSimpleToast("Not implemented", true);
      }
      if (oneClickAction === "seedOrderForm") {
        icToastService.showSimpleToast(
          "This has been replaced by duplicate order",
          true
        );
        // var url = `${$rootScope.orderUrl}/invoice/${vm.invoice.url_key}/renew/${vm.invoice.invoice_id}`;
        // window.open(url);
        return false;
      }
      if (oneClickAction === "viewOrderConfirmation") {
        var url = `${$rootScope.orderUrl}/invoice/${vm.invoice.invoice_guid}`;
        window.open(url);
        return false;
      }
      if (oneClickAction === "duplicateOrder") {
        vm.processing = true; // disable the button so that it doesn't get clicked twice
        oneClickService
          .duplicate(vm.invoice.invoice_id)
          .then(function (data) {
            vm.processing = false; // enable the button

            showInvoiceNavToast(data.invoice_id);
          })
          .catch(function (err) {
            icToastService.showSimpleToast(err, true);
          });
        return false;
      }

      vm.processing = true; // disable the button so that it doesn't get clicked twice
      oneClickService
        .update(vm.invoice.invoice_id, oneClickAction)
        .then(function (data) {
          vm.invoice = data;
          $rootScope.invoice = vm.invoice;
          invoicesService.refreshOne(vm.invoice);
          vm.processing = false; // enable the button
          icToastService.showSimpleToast("Invoice successfully updated");
        })
        .catch(function (err) {
          icToastService.showSimpleToast(err, true);
        });
    };

    vm.$onInit = function () {
      // Get the invoice data
      invoicesService
        .getOne(vm.invoiceId)
        .then(function (data) {
          vm.invoice = data;
          // get the one click options
          oneClickService
            .get(vm.invoiceId, vm.invoice.template_id)
            .then(function (data) {
              vm.stages = data;
            })
            .catch(function (err) {
              throw err;
            });
        })
        .catch(function (err) {
          icToastService.showSimpleToast("Error with oneClick options", true);
        });
    };
  },
];

import template from "./one-click.html";
export default {
  bindings: { invoiceId: "<" },
  controllerAs: "vm",
  controller: myController,
  template,
};

const dates = function(  ) {
	
    this.getDueDate = function(myInvoice) {
    	// Date invoice is due
        if(myInvoice.date_first_invoice) {
        	var dateObj = moment(myInvoice.date_first_invoice);
            dateObj.add(myInvoice.invoice_term, 'days');
            const myDate = dateObj.format("MMM D, YYYY");
            return myDate;
        }  else {
        	return '';
        }
    	

    };
	
    this.getTermDates = function(myInvoice) {
    	// START AND END DATE OF LICENSE
    	var myStartDate = +myInvoice.academic_year -1;
        var myTerm = myInvoice.license_term;
        var myDates = this.generateTermDates(myStartDate, myTerm);
        return myDates[0] + '-' + myDates[1];

    };
    this.getExpirationDate = function(myInvoice) {
    	// DATE THE LICENSE EXPIRES
    	var myStartDate = +myInvoice.academic_year -1;
        var myTerm = myInvoice.license_term;
        var myDates = this.generateTermDates(myStartDate, myTerm);
        return myDates[1];
    }; 
    this.getStartDate = function(myInvoice) {
    	// DATE THE LICENSE BEGINS
    	var myStartDate = +myInvoice.academic_year -1;
        var myTerm = myInvoice.license_term;
        var myDates = this.generateTermDates(myStartDate, myTerm);
        return myDates[0];
    };        
    
    this.generateTermDates = function(startYear, termNumber) {
        var myStartDate = startYear;
        myStartDate += '-07-01';
        
        // moment.js wants a js formed date, functionality is deprecated        
        myStartDate = new Date(myStartDate);
        
        var myEndDate;
        
        var startDateObj = moment(myStartDate);
        startDateObj.set({'month': 6, 'date':1});    	
        var endDateObj = moment(myStartDate);

        endDateObj.add(termNumber, 'years');
        myStartDate = startDateObj.format("MMM YYYY");
        myEndDate = endDateObj.format("MMM YYYY");
        return [myStartDate, myEndDate];
        //return myStartDate + '-' + myEndDate;
    }; 
    
    this.addDays = function(myDate, days) {
        const myDateJS = new Date(myDate);      
        dateObj = moment(myDateJS).add(days, 'day');                        
        var  formattedDate = dateObj.format("MMM DD, YYYY");       
        return formattedDate;
    };   

    
    this.formatToday = function(){
    	const dateObj = moment();
        var  formattedDate = dateObj.format("MMM DD, YYYY");
        return formattedDate;
    };   
    
    this.prepStartYear = function(academicYear){
    	
    	// untested
    	if(academicYear) {
    		return academicYear;    		
    	} else {
        	// Get current date        	    	
        	var nowObj = moment();
	
        	if((nowObj.get('month') < 6 ) && (nowObj.get('month') <= 11 ))  {    	
        		nowObj.add(-1, 'years');	
        	}
        	
        	// Set the date as starting on July 1st
        	// Not really used, but whatever
        	nowObj.set({'month': 6, 'date':1});
        	return nowObj.format("YYYY");
    	}
    	
    	
    };
    
    
    this.getAcademicYearOptions = function(academicYear, stepBackNumber){	
    	
    	
    	 /* 
    	  * This method takes an existing invoice data (could be from a template order)
    	  *  and then generates a list of options for the academic year 
    	  *  e.g., 2017 => "July 2016- July 2017"
    	  *  
    	  *  Since this could be a new order or existing invoice, 
    	  *  the first academic year needs to default to and existing record 
    	  *  
    	 */
    	
    	// Get current date        	    	
    	var nowObj = moment();
    	
    	// Subtract a year if the it is between Januaray and June
    	// in order to keep true to the fiscal year
    	// - note that months are zero indexed, so July is 6    	
    	if((nowObj.get('month') < 6 ) && (nowObj.get('month') <= 11 ))  {    	
    		nowObj.add(-1, 'years');	
    	}
    	
    	// Set the date as starting on July 1st
    	// Not really used, but whatever
    	nowObj.set({'month': 6, 'date':1});    		
    	
    	// We may need to override all of the calculations from above
    	// if this is an existing order...
    	var startYear = nowObj.format("YYYY");    	
    	var academicYear = +academicYear;
    	    	     	
    	//if( (academicYear > 2014) && (academicYear < startYear)) {
    	if(academicYear > 2014) {
    		 startYear = academicYear - 1;
    	}
    	
    	// Create an array for the options
    	var optionArray = [];
    	// Go two years out
    	var endYear = +startYear + 2;
    	// Iterate over the years and create the date range
    	
    	var stepBackStartYear = startYear;
    	if (stepBackNumber) {stepBackStartYear -= stepBackNumber;}
    	
    	for (var i = stepBackStartYear; i < endYear; i++ ) {    		
             var dateRange = this.generateTermDates(i, 1);
    		 var fiscalYear = +i + 1;
    		 optionArray.push({fiscalYear:fiscalYear, startEnd: dateRange[0]});
    	}
    	
    	 return (optionArray);
    };      
    
  

};

export default dates;
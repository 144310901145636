    import template from './nav-center.tpl.html';

    const navCenter = [  '$mdSidenav', '$mdUtil',
                   function (  $mdSidenav, $mdUtil ) {
	

        var myLink = function (scope, element, attrs) {

			scope.toggleLeft = buildToggler('left');
			scope.toggleRight = buildToggler('right');
			
			/**
			 * Build handler to open/close a SideNav;
			 */
			function buildToggler(navID) {
			var debounceFn =  $mdUtil.debounce(function(){
					$mdSidenav(navID)
					.toggle()
					.then(function () {
						//$log.debug("toggle " + navID + " is done");
					});
				},200);
			return debounceFn;
			}
                  
	     
        };        

        return {
            restrict: "E",
            link: myLink,
			transclude:true,
			replace:true,
            scope: {
     
            },
            template
        };
    }];


export default navCenter;
// var underscore = angular.module('underscore', []);
// underscore.factory('_', function() {
//   return window._; //Underscore must already be loaded on the page
// });

/**
 * Dynamically load environment variables
 * Prior to loading angular js set the variables in the browsers window.__env.x 
 * Currently using ParcelJs to load from the .env file at build time
**/ 
// window.__env.apiUrl;
// window.__env.orderUrl;
var env = {};

if(window){  
    Object.assign(env, window.__env);
}


import angular from 'angular';
import 'angular-animate';
import '@uirouter/angularjs';
import 'angular-local-storage';
import 'ngstorage';
import 'ui-bootstrap4';
import 'angular-material';

import 'angular-aria';
import 'angular-messages';
import 'angular-sanitize';
import 'ng-resource';
import 'ng-file-upload';
import 'ng-csv';
import 'angular-jwt';


import './services/api-service.module';
import './common/tools.module';
import './common/filters.module';
import './common/auth/auth.module';
import './common/interceptors/interceptors.module';

import './modules/navigation/nav.module';
import './modules/invoice-list/invoice-list.module';
import './modules/one-click/one-click.module';
import './modules/invoice-services/services.module';

import './modules/invoice-terms/invoice-terms.module';
import './modules/case-manager/case-manager.module';





export default angular.module('invoicer', [
  'ngAnimate',
  'ui.router',  
  'ngSanitize', 
  'ui.bootstrap',
  'ngCsv',
  'ngMaterial',
  'ngAria',
  'ngStorage',
  'ngFileUpload',
  'LocalStorageModule',

  'invoicer.filters',
  'invoicer.tools',

  'auth',
  'interceptors',
  'invoicer.api.service',

  'invoicer.nav',
  'invoicer.list',
  'invoicer.oneClick',
  'invoicer.services',

  'invoicer.invoice-terms',
  'invoicer.case-manager',
])
.constant('AUTH_EVENTS', {
  loginSuccess: 'auth-login-success',
  loginFailed: 'auth-login-failed',
  logoutSuccess: 'auth-logout-success',
  sessionTimeout: 'auth-session-timeout',
  notAuthenticated: 'auth-not-authenticated',
  notAuthorized: 'auth-not-authorized'
})
.run(
  [           '$rootScope', '$state', '$stateParams', '$trace', 'AUTH_EVENTS',
    function ($rootScope,   $state,   $stateParams, $trace, AUTH_EVENTS) {

    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;
    
    $rootScope.api = env.apiUrl;
    $rootScope.orderUrl = env.orderUrl;
    
    // $rootScope.$on('$locationChangeSuccess', function(e) {
        // if($stateParams.status) {
        //     activeState.setStatus($stateParams.status);
        // }
        // if($stateParams.stage) {
        //     activeState.setStage($stateParams.stage);
        // }
        // if($stateParams.invoiceId) {
        //     activeState.setInvoiceID($stateParams.invoiceId);
        // }                
      // });


    // $trace.enable('TRANSITION');

    
    $rootScope.$on(AUTH_EVENTS.loginSuccess, (event, data) => {
      // reload state
      // Throws an error if there is no state, such as when it is an initial login      
      if(!$state.current.name) {
        $state.go('invoices.list', {status: 'open', stage:1}, {reload: true})
      } else {
        $state.go($state.current, {}, {reload: true});
      }
    });
    }
  ]
)
.config(['$mdThemingProvider', function($mdThemingProvider) {
	  var customBlueMap = 		$mdThemingProvider.extendPalette('light-blue', {
		    'contrastDefaultColor': 'light',
		    'contrastDarkColors': ['50'],
		    '50': 'ffffff'
		  });	
  $mdThemingProvider.definePalette('customBlue', customBlueMap);
  
  $mdThemingProvider.theme('default')
    .primaryPalette('orange')
    .accentPalette('indigo');
  $mdThemingProvider.theme('toolbar', 'default')
      .primaryPalette('customBlue', {
      'default': '500',
      'hue-1': '50'
    });
    $mdThemingProvider.theme("success-toast");
    $mdThemingProvider.theme("error-toast");    

}])

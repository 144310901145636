import template from './notes.tpl.html';
const myController = ['icToastService', 'notesService', function (icToastService, notesService) {
    var vm = this;
    vm.loading = true;

    vm.add = function () {
        if (!vm.newNote) {
            icToastService.showSimpleToast("No note was entered");
            return false;
        }            

        notesService.api.add(vm.invoiceId, vm.newNote)
            .then(function (data) {
                vm.newNote = '';
                vm.notes.unshift(data);
                icToastService.showSimpleToast('Note sucessfully added');                
            })
            .catch(function(err) {                   
                console.log(err) 
                icToastService.showSimpleToast(err, true);
            })
    }

    vm.update = function (item) {
        var originalNote = item.note;
        item.note = item.temp_note;

        notesService.api.update(vm.invoiceId, item)
            .then(function (data) {
                icToastService.showSimpleToast('Note sucessfully updated');
        })
        .catch(function(err) {
            item.note = originalNote;
            icToastService.showSimpleToast(err, true);
        })
    }

    vm.delete = function (item) {
        vm.notes = vm.notes.filter(x => x.note_id !== item.note_id);
        notesService.api.delete(vm.invoiceId, item)
            .then(function (data) {                    
                icToastService.showSimpleToast('Note sucessfully deleted');
        })
        .catch(function(err) {
            vm.notes = vm.notes.filter(x => x.note_id !== item.note_id).concat(item);
            icToastService.showSimpleToast(err, true);
        })
    }           
    vm.$onInit = function () {
        // NEED TO GET THE CLIENT NOTES
        vm.notes = [];
        notesService.api.get(vm.invoiceId).then(function (data) {
            vm.notes = data;
            vm.loading = false;
        });
    }
}];

const notes =  {
    bindings: { invoiceId: '<' },
    controllerAs: 'vm',
    controller: myController,
    template
};

export default notes;

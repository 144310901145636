const activeState = function () {
  var activeState = {};

  var state = {
    status: "open",
    stage: { open: 1, closed: -1 },
    invoiceId: null,
    display: "services",
    searchFilter: null,
    loading: false,
  };

  activeState.get = function () {
    return activeState.currentState;
  };

  activeState.getState = function () {
    return state;
  };
  activeState.setStatus = function (status) {
    return (state = Object.assign(state, { status }));
  };
  activeState.setInvoiceId = function (invoiceId) {
    return (state = Object.assign(state, { invoiceId }));
  };
  activeState.setDisplay = function (display) {
    return (state = Object.assign(state, { display }));
  };

  activeState.setStage = function (stage) {
    if (state.status === "open") {
      return (state = Object.assign(state, {
        stage: { open: stage, closed: state.stage.closed },
      }));
    } else if (state.status === "closed") {
      return (state = Object.assign(state, {
        stage: { closed: stage, open: state.stage.open },
      }));
    }
  };

  activeState.getStage = function () {
    return state.status === "open" ? state.stage.open : state.state.closed;
  };

  activeState.getOpenStage = function () {
    return state.stage.open;
  };
  activeState.getClosedStage = function () {
    return state.stage.closed;
  };

  activeState.getStatus = function () {
    return state.status;
  };

  activeState.setSearchFilter = function (searchFilter) {
    return (state = Object.assign(state, { searchFilter }));
  };
  activeState.getSearchFilter = function () {
    return state.searchFilter;
  };

  activeState.setLoading = function (loading) {
    return (state = Object.assign(state, { loading }));
  };
  activeState.getLoading = function () {
    return state.loading;
  };

  return activeState;
};

export default activeState;

import template from './invoice-terms.tpl.html';

const myController = ['icToastService', 'dates', 'invoicesService', function (icToastService, dates, invoicesService) {
    var vm = this;
    vm.dates = dates;

    vm.$onInit = function () { 
        // Get the invoice data
        invoicesService.getOne(vm.invoiceId).then(function (data) {                
            vm.invoice = data;
        }).catch(function (err) {
            icToastService.showSimpleToast(err, true);
        })
    }
}];


const invoiceTerms = {
    bindings: { invoiceId: '<', invoice: '<' },
    controllerAs: 'vm',
    controller: myController,
    template
};

export default invoiceTerms;
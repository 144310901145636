
    const Authorization = [ '$state', function ($state) {
        this.authorized = false;
        this.memorizedState = null;

        var clear = function () {
                this.authorized = false;
                this.memorizedState = null;
            },

            go = function (fallback) {
                //this.authorized = true;
                var targetState = this.memorizedState ? this.memorizedState : fallback;
                $state.go(targetState);
            };

        return {
            authorized: this.authorized,
            memorizedState: this.memorizedState,
            clear: clear,
            go: go
        };
    }];

    export default Authorization;
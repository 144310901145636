import template from "./edit-wrapper.tpl.html";
const myController = [
  "icToastService",
  "$state",
  "invoicesService",
  function (icToastService, $state, invoicesService) {
    var vm = this;
    vm.invoice = {};

    vm.cancel = function () {
      $state.go("^", { invoiceId: vm.invoiceId });
    };

    vm.save = function () {
      invoicesService
        .updateDetails(vm.invoice)
        .then(function (data) {
          $state.go("^", { invoiceId: vm.invoiceId }, { reload: true });
        })
        .catch(function (err) {
          icToastService.showSimpleToast(err, true);
        });
    };
  },
];

const editWrapper = {
  bindings: { invoiceId: "<", invoice: "<" },
  transclude: true,
  controllerAs: "vm",
  controller: myController,
  template,
};

export default editWrapper;

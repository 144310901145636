import billingEdit from './billing-edit/billing-edit.component';
import editWrapper from './edit-wrapper/edit-wrapper.component';
import invoiceIdEdit from './invoice-id-edit/invoice-id-edit.directive';
import invoiceTerms from './invoice-terms/invoice-terms.component';
import invoiceTermsEdit from './invoice-terms-edit/invoice-terms-edit.component';
import requestorEdit from './requestor-edit/requestor-edit.component';

angular.module('invoicer.invoice-terms', [])
.component('billingEdit', billingEdit)
.component('editWrapper', editWrapper)
.directive('invoiceIdEdit', invoiceIdEdit)
.component('invoiceTerms', invoiceTerms)
.component('invoiceTermsEdit', invoiceTermsEdit)
.component('requestorEdit', requestorEdit);



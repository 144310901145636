import utils from './tools/utils.service'
import icToastService from './tools/ic-toast.service'
import dates from './tools/dates.service'
import totals from './tools/totals.service'


angular.module('invoicer.tools', [])
.factory('utils', utils)
.service('icToastService', icToastService)
.service('dates', dates)
.service('totals', totals)
import template from './sites.tpl.html';

const myController = ['icToastService', 'sitesService', function (icToastService, sitesService) {
    var vm = this;
    vm.sites = [];
    vm.loading = true;                     
    vm.edit = { site_id: '' };
       
    vm.editSite = function (site) {
        vm.edit.site_id = site.selected_site_id;                
        site.temp_site_id = site.site_id;
        site.temp_name = site.site_name;
        site.temp_address = site.site_address;
    }
    
    vm.update = function (site) {
        site.site_id = site.temp_site_id;
        site.site_name = site.temp_name;
        site.site_address = site.temp_address;                
    
        sitesService.api.update(vm.myid, site).then(function(data){
                if(data.success) {         
                    vm.edit.site_id = '';
                    icToastService.showSimpleToast(data.message);
                    
                } else {
                    icToastService(data.err, true)                    
                }                                                          
        });
    }
    

    vm.$onInit = function(){
        sitesService.api.get(vm.invoiceId).then(function(data){                     
            vm.sites = data;
            vm.loading = false;            
        });
    }
}];

const sites = {
    bindings: { invoiceId: '<', invoice: '<'},
    controllerAs: 'vm',
    controller: myController,
    template
};
    
export default sites;

    const getLicensing = function(input, multiplier = 1, discount = 0) {
        
        const subTotal = input.reduce((memo,obj) => { return memo + (obj.price * obj.quantity); }, 0 );
        const passthrough = input.reduce((memo,obj) => { return memo + (obj.passthrough_revenue * obj.quantity); }, 0 );
        const royaltySubTotal = input.reduce((memo,obj) => { return memo + ( (obj.price * obj.licensing_indirect) * obj.quantity); }, 0 );
        
        // rawTotal is the total before discount is applied
        const rawTotal = multiplier ? subTotal * multiplier : subTotal;
        // total is the actual total
        const total = discount ? (rawTotal - discount) : rawTotal;

        // use the percentage of the discount so that it can be split between the service and royalty
        const discountPct = (total / rawTotal);
        const royalty = (royaltySubTotal * discountPct) - passthrough;

        
        const serviceRev = total - (royalty + passthrough);

        return { royalty: royalty, passthrough: passthrough, subTotal: subTotal, serviceRev: serviceRev, total: total };

    }


    angular
        .module('invoicer.filters', [])
        .filter('modules', function() {
            return function(input) {
                if(!Array.isArray(input)) {                    
                    return [];
                };
                // Filters down to the parent service items (module)
                return input.filter(function (el) { return ( (el.parent_selected_service_id === null) || (el.parent_selected_service_id === el.selected_service_id) )});
            }
        })
        .filter('formExtensions', function() {
            // Filters down to the service items assigned to the parent service (module)
            return function(input) {
                if(!Array.isArray(input)) {
                    return [];
                };
                return input.filter(function (el) { return el.parent_template_service_id !== null});                
            }
        })
        .filter('formModules', function() {
            // Filters down to the service items assigned to the parent service (module)
            return function(input) {
                if(!Array.isArray(input)) {
                    return [];
                };
                return input.filter(function (el) { return el.parent_template_service_id === null});
            }
        })                     
        .filter('extensions', function() {
            // Filters down to the service items assigned to the parent service (module)
            return function(input, selected_service_id) {                
                if(!Array.isArray(input)) {
                    return [];
                };
                return input.filter(function (el) { return el.parent_selected_service_id === selected_service_id});
            }
        })        
        .filter('servicesSubtotal', function() {
            return function(input) {                         
                if(!Array.isArray(input)) {                    
                    return [];
                };
                const totals = getLicensing(input);
                return totals.subTotal;                
            }
        })
        .filter('servicesTotal', function() {
            return function(input, multiplier, discount) {
                if(!Array.isArray(input)) {                                      
                    return [];
                };
                const totals = getLicensing(input, multiplier, discount);
                return totals.total;
            }
        })
        .filter('royaltyIndirects', function() {
            return function(input, multiplier, discount) {
                if(!Array.isArray(input)) {                                      
                    return [];
                };
                const totals = getLicensing(input, multiplier, discount);
                return totals.royalty;
            }
        })
        .filter('serviceIndirects', function() {
            return function(input, multiplier, discount) {
                if(!Array.isArray(input)) {                                      
                    return [];
                };
                const totals = getLicensing(input, multiplier, discount);
                return totals.serviceRev;
            }
        })
        .filter('passthrough', function() {
            return function(input, multiplier, discount) {
                if(!Array.isArray(input)) {                                      
                    return [];
                };
                const totals = getLicensing(input, multiplier, discount);
                return totals.passthrough;
            }
        })
        .filter('unsafe',['$sce', function ($sce) {
            return function(val) {
                return $sce.trustAsHtml(val);
            };
        }]);


import invoiceListState from "./invoice-list.service";
import invoiceDashboard from "./invoice-dashboard/invoice-dashboard.component";
import invoiceListItem from "./invoice-list-item/invoice-list-item.component";
import activeState from "../navigation/nav.service";

angular
  .module("invoicer.list", [])
  .service("invoiceListState", invoiceListState)
  .component("invoiceDashboard", invoiceDashboard)
  .component("invoiceListItem", invoiceListItem)
  .service("activeState", activeState);

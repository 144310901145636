import template from './services-total.tpl';

const initValues = {
    subTotal: 0,
    passthrough: 0,
    subjectToIndirects: 0,
    royaltySubTotal: 0,
    royalty: 0,
    serviceRev: 0,
    taxes: 0,
    total: 0
};


const myController = [function () {
    var vm = this;
    let counter = 1;
    vm.editTax = false;

    // Semi-expensive change detection
    vm.$doCheck = function (changes) {            
        vm.totals = recalcTotals(counter++);
    };

    vm.$onInit = function () {
        vm.totals = recalcTotals(counter)
    }

    const recalcTotals = () => {            
        const discount = vm.discount;
        let totals = vm.modules.reduce((memo, obj) => {
            const subTotal = memo.subTotal + (obj.price * obj.quantity);
            const passthrough = memo.passthrough + (obj.passthrough_revenue * obj.quantity);
            const taxes = vm.taxPct * (subTotal - discount);
            const total =(subTotal - discount) + taxes;
            const subjectToIndirects = subTotal - discount - passthrough;
            const royaltySubTotal = memo.royaltySubTotal + ( ((obj.price - obj.passthrough_revenue) * obj.licensing_indirect) * obj.quantity);
            const royalty = royaltySubTotal;
            const serviceRev = memo.serviceRev + ( ((obj.price - obj.passthrough_revenue) * (1 - obj.licensing_indirect)) * obj.quantity);
            
            return {...memo, subTotal, total, passthrough, subjectToIndirects, royaltySubTotal, royalty, serviceRev, taxes}
        }, initValues);
        if (discount) {
            // Currently there is no way to know how to split a discount so it is split evenly between the two indirect types
            const royaltyFairShare = totals.royalty - ((totals.royaltySubTotal / (totals.subTotal - totals.passthrough)) * discount);
            const serviceFairShare = totals.subjectToIndirects - royaltyFairShare;
            totals = {...totals, royalty: royaltyFairShare, serviceRev: serviceFairShare};            
        }
        const currentTotal = Math.round(totals.passthrough*100)/100 + 
            Math.round(totals.royalty*100)/100 +
            Math.round(totals.serviceRev*100)/100 + 
            Math.round(totals.taxes*100)/100;
        if (totals.total === currentTotal)
        {
        return {
            ...totals,
            taxes: totals.taxes * vm.licenseTerm,
            total: totals.total * vm.licenseTerm,
            passthrough: totals.passthrough * vm.licenseTerm,
            royalty: totals.royalty * vm.licenseTerm,
            serviceRev: totals.serviceRev * vm.licenseTerm,
         }
        }
        else{
            return {
                ...totals,
                taxes: totals.taxes * vm.licenseTerm,
                total: totals.total * vm.licenseTerm,
                passthrough: totals.passthrough * vm.licenseTerm,
                royalty: totals.royalty * vm.licenseTerm,
                serviceRev: (totals.total - (Math.round(totals.passthrough*100)/100) - (Math.round(totals.royalty*100)/100)-(Math.round(totals.taxes*100)/100)) * vm.licenseTerm
            }
        }
        
    }

}];

const servicesTotal = {
    bindings: { 
        modules: '<', 
        taxPct: '<',
        licenseTerm: '<',
        discount: '<'
    },
    controllerAs: 'vm',
    controller: myController,
    template
};
export default servicesTotal;


import template from './login-dialog.tpl';
const loginDialog = [
                '$mdDialog', 'icToastService', 'loginService', 'AUTH_EVENTS', 'Authorization',
    function (  $mdDialog,    icToastService,  loginService,   AUTH_EVENTS,   Authorization)  {
    
    var myController = function () {          
        var vm = this;
        
        vm.closeModal = function (ev) {
                $mdDialog.hide();
        }
        
            vm.loadModal = function (ev) {                
            $mdDialog.show({
                openFrom: {
                    top: -50,
                    width: 30,
                    height: 80
                },
                fullscreen:true,
                parent: angular.element(document.body),
                closeTo: {
                    left: 1500
                },
                template,
                locals: {
                    timezone: vm.timezone,
                    locals:{logmein: vm.logmein},
                },
                controller: [
                    function ( ) {
                        vm = this;
                        vm.processing = false;                         
                        vm.login = function(isValid) {
                            if(!isValid) {
                                return false;
                            }
                            vm.processing = true;
                            var myData = 
                                {
                                'username': vm.username
                                , password:vm.password
                                // , timeZone: $cookies.get('timezone')
                                };
                            
                            loginService.account.login(myData)
                            .then(function(data){
                                $mdDialog.cancel();
                                    
                            }).catch(function(err) {
                                vm.processing = false;
                                icToastService.showSimpleToast("incorrect login", true);
                            });
                        }                                          
                    }
                ],
                controllerAs: 'vm'
            });                
        }        
    };         
        
    return {
            restrict : "E",
            //transclude:true,
            controller:myController,
            controllerAs: 'vm',
            bindToController: true,
            scope : {
                auth: '=',
            },
            link:function(scope, element, attr){
                scope.vm.isModalOpen = false;                    
                // if(!sessionStorage.getItem("tokenKey")) {                      
                //     scope.vm.isModalOpen = true;
                //     scope.vm.loadModal();                       
                // }
                    scope.$on('AUTH', function (event, data) {
                    //  console.log("AUTH emitted to login directive", data)
                        if(data.status && scope.vm.isModalOpen) {
                        scope.vm.isModalOpen = false;
                        scope.vm.closeModal();
                        } else if(!data.status && !scope.vm.isModalOpen) {
                        scope.vm.isModalOpen = true;
                        scope.vm.loadModal();
                    }
                });
            }
    };
}];    

export default loginDialog;
    
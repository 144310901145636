import template from './invoice-terms-edit.tpl.html';
const myController = ['icToastService', 'dates', 'invoicesService', function (icToastService, dates, invoicesService) {
    var vm = this;
    vm.dates = dates;
    vm.paymentOptions = [];
    vm.invoice_terms = [1,2,3,4,5];

    vm.$onInit = function () {
        // // Get the invoice data
        invoicesService.getOne(vm.invoiceId).then(function (data) {
            vm.invoice = data;
            // set the academic year
            vm.academicYearOptions = vm.dates.getAcademicYearOptions(vm.invoice.academic_year, 1); 
        }).catch(function (err) {
            icToastService.showSimpleToast(err, true);
        });
        // Get the payment options
        invoicesService.paymentOptions(vm.invoiceId).then(function (data) {
            vm.paymentOptions = data;
        }).catch(function (err) {
            icToastService.showSimpleToast(err, true);
        })            
    }
}];

const invoiceTermsEdit = {
    bindings: { invoiceId: '<', invoice: '<' },
    controllerAs: 'vm',
    controller: myController,
    template
};

export default invoiceTermsEdit;
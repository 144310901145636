import template from "./nav-left.tpl.html";

const myController = [
  "activeState",
  "$state",
  function (activeState, $state) {
    var vm = this;
    vm.state = activeState.getState();
    vm.loading = false;

    vm.navLinks = [
      {
        status: "open",
        LinkText: "Open",
        stage: vm.state.stage.open,
      },
      {
        status: "closed",
        LinkText: "Closed",
        stage: vm.state.stage.closed,
      },
    ];
    vm.changeStatus = function (status, stage) {
      vm.loading = activeState.getLoading();

      if (!vm.loading) {
        $state.go(
          "invoices.list",
          { status: status, stage: stage },
          { reload: true }
        );
      }
    };
    vm.$onInit = function () {};
  },
];

export default {
  bindings: { invoiceId: "<" },
  controllerAs: "vm",
  controller: myController,
  transclude: true,
  template,
};

const oneClickService = [
  "$rootScope",
  "$http",
  "$q",
  function ($rootScope, $http, $q) {
    var myService = this;

    myService = {
      oneClickCache: [],
      _fetch: function fetch(invoiceId, template_id) {
        if (
          this.oneClickCache.length &&
          this.oneClickCache.find((x) => x.template_id === template_id)
        ) {
          return $q
            .when(
              this.oneClickCache.find((x) => x.template_id === template_id)
                .options
            )
            .then(function (items) {
              return items;
            });
        } else {
          return $http({
            method: "get",
            url: `${$rootScope.api}/api/admin/options/oneClick/${invoiceId}`,
          })
            .then((resp) => {
              this.oneClickCache.push({
                template_id: template_id,
                options: resp.data,
              });
              return resp.data;
            })
            .catch(function (err) {
              throw err;
            });
        }
      },
      get: function get(invoiceId, template_id) {
        return this._fetch(invoiceId, template_id);
      },
      update: function update(invoiceId, statusAction) {
        return $http({
          method: "put",
          url: `${$rootScope.api}/api/admin/invoice/status/${invoiceId}/${statusAction}`,
        })
          .then(function (resp) {
            return resp.data;
          })
          .catch(function (err) {
            return err;
            //return "Error updating invoice status";
          });
      },
      duplicate: function duplicate(invoiceId) {
        return $http({
          method: "post",
          url: `${$rootScope.api}/api/invoice/duplicate/${invoiceId}`,
        })
          .then(function (resp) {
            return resp.data;
          })
          .catch(function (err) {
            return "Error duplicating order";
          });
      },
    };

    return myService;
  },
];

export default oneClickService;


const eventsService = ['$rootScope', '$http',
            function ($rootScope, $http) {
    
    var myService = this;
    
    myService.api = {
        get: function get(invoiceId) {
            return $http({
                method: "get",
                url: `${$rootScope.api}/api/admin/invoice/logs/${invoiceId}`
            }).then(function (resp) {                    
                return resp.data;
            }).catch(function(err) {
                return 'Error retrieving event log';
            });
        },
        update: function get(invoiceId, statusAction) {
            return $http({
                method: "get",
                url: `${$rootScope.api}/api/admin/invoice/status/${invoiceId}/${statusAction}`
            }).then(function (resp) {                    
                return resp.data;
            }).catch(function(err) {
                return 'Error retrieving event log';
            });
        },            
    };    
    
    
    return myService;
}];

export default eventsService;
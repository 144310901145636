import template from "./invoice-dashboard.tpl.html";

const myController = [
  "invoicesService",
  "invoiceListState",
  "icToastService",
  "$filter",
  "$state",
  "activeState",
  function (
    invoicesService,
    invoiceListState,
    icToastService,
    $filter,
    $state,
    activeState
  ) {
    const vm = this;
    vm.loading = true;
    activeState.setLoading(true);
    vm.invoiceList = [];

    vm.orderByOptions = {
      active: invoiceListState.display.orderBy.field.get(),
      ascending: invoiceListState.display.orderBy.ascending.get(),
      items: [
        { field: "academic_year", display: "Academic year" },
        { field: "date_created", display: "Order Date" },
        { field: "date_first_invoice", display: "Invoice Date" },
        { field: "invoice_id", display: "Invoice Number" },
        { field: "template_wrapper_name", display: "Template" },
        { field: "fee_total", display: "Amount" },
        { field: "bill_city", display: "City" },
      ],
    };
    vm.searchFilter = invoiceListState.display.search.get();

    // SET THE VARIABLES FOR THE PAGINATION DIRECTIVE
    vm.currentPage = 1;
    vm.maxSize = 3;
    vm.displayQuantity = 10;
    vm.displayOptions = [10, 20, 50, 100]; // Options that end in 5 don't seem to work

    vm.pageChanged = () => {
      invoiceListState.display.orderBy.field.set(vm.orderByOptions.active);
      invoiceListState.display.orderBy.ascending.set(
        vm.orderByOptions.ascending
      );
      invoiceListState.display.search.set(vm.searchFilter);

      var params = {
        offset: (vm.currentPage - 1) * vm.displayQuantity,
        limit: vm.displayQuantity,
        searchFilter: vm.searchFilter,
        status: vm.stage,
        invoice_level: vm.status,
        orderBy: vm.orderByOptions.active,
        ascending: vm.orderByOptions.ascending,
      };

      vm.invoices = $filter("orderBy")(
        $filter("filter")(vm.invoiceList, vm.searchFilter),
        params.orderBy,
        params.ascending
      ).slice(params.offset, params.offset + params.limit);
      vm.displayFrom = (vm.currentPage - 1) * vm.displayQuantity + 1;
      vm.displayTo = vm.displayFrom + vm.displayQuantity - 1;

      vm.totalItems = $filter("filter")(vm.invoiceList, vm.searchFilter).length;
      // vm.statusCount = vm.invoiceList.length;
      vm.statusCount = vm.totalItems;
      vm.displayText =
        vm.displayQuantity <= vm.totalItems
          ? vm.displayFrom + " - " + vm.displayTo
          : vm.totalItems;
      vm.displayText += " of " + vm.statusCount;
    };

    vm.$onInit = function () {
      // Redirect if needed
      if (vm.status === "closed" && vm.stage > 0) {
        $state.go("invoices.list", { status: "closed", stage: -1 });
      }
      if (vm.status === "open" && vm.stage < 0) {
        $state.go("invoices.list", { status: "open", stage: 1 });
      }
      //get invoice list invoice service
      invoicesService
        .list(vm.stage, vm.status)
        .then(function (data) {
          vm.invoiceList = data;
          vm.loading = false;
          //update nav menu status
          activeState.setLoading(false);
          vm.pageChanged();
        })
        .catch(function (err) {
          icToastService.showSimpleToast(err, true);
        });
    };
  },
];

export default {
  bindings: { status: "<", stage: "<" },
  controllerAs: "vm",
  controller: myController,
  template,
};

import template from './service-edit.tpl.html';

const serviceEdit = ['$mdToast', '$filter', 'servicesService', function ($mdToast, $filter, servicesService) {
    var myLink = function (scope, element, attrs) {
        scope.allowDelete = false;
        scope.enableDelete = function() {
            scope.allowDelete = true;
        }
    };        

    return {
        restrict: "E",
        link: myLink,
        scope: {
            item: '=',
            save: '&',
            cancel: '&',
            delete: '&'
        },
        template,
    };
}];
export default serviceEdit;
    
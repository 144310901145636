import template from './files.tpl.html';

const myController = ['icToastService', 'filesService', function (icToastService, filesService) {
    var vm = this;
    vm.loading = true;
    vm.documents = [];
    vm.fileName = { name: ''};

    vm.iconMapping = {"application/pdf": "file-pdf-o", 
    "full-time": "full_circle.jpg", 
    "former": "empty_circle.jpg"
    };

    vm.downloadFile = function(documentName) {
        filesService.api.getTempUrl(vm.invoiceId, documentName);
        return false;
    }

    vm.upload = function(  ) {
        vm.loading = true;


        try {
            if(vm.documents.find(x => x.documentName === vm.fileName.name)) {
                icToastService.showSimpleToast('File already exists with that name', true);
                throw new Error('File already exists with that name');
            }
            vm.documents = vm.documents.filter(x => x.documentName !== vm.fileName.name);
            filesService.api.add(vm.invoiceId, vm.fileName)
                .then(function (data) {
                    icToastService.showSimpleToast('Document sucessfully added');
                    vm.documents = vm.documents.concat(data);
                    vm.loading = false;
                    vm.fileName = { name: ''};
            })
            .catch(function(err) {
                icToastService.showSimpleToast(err, true);
                vm.loading = false;
            });
        } catch (err) {
            vm.loading = false;
        }
    }

    vm.deleteFile = function (document) {
        vm.documents = vm.documents.filter(x => x.documentName !== document.documentName);
        filesService.api.delete(vm.invoiceId, document.documentName)
            .then(function (data) {                    
                icToastService.showSimpleToast('Document sucessfully deleted');
        })
        .catch(function(err) {
            vm.documents = vm.documents.filter(x => x.documentName !== item.documentName).concat(documentName);
            icToastService.showSimpleToast(err);
        });
    }   
            

    vm.$onInit = function () {
        // NEED TO GET  PREVIOUSLY SUBMITTED DOCUMENTS
        filesService.api.get(vm.invoiceId).then(function (data) {
            vm.loading = false;
            vm.documents = data;
        })
        .catch(function(err) {              
            icToastService.showSimpleToast(err, true);
        })            
    }
}];

const files = {
    bindings: { invoiceId: '<' },
    controllerAs: 'vm',
    controller: myController,
    template
};

export default files;
  /**
   * Allows variables to be dynamically set via ParcelJS
   */
  
  module.exports = (function () {        
        window.__env = window.__env || {};
        if(!process.env.API_URL) {
            alert('API_URL is not sent in the .env');
        } else {            
            window.__env.apiUrl = process.env.API_URL;
        }
        if(!process.env.ORDER_URL) {
            alert('ORDER_URL is not sent in the .env');
        } else {
            window.__env.orderUrl = process.env.ORDER_URL;
        }
        // Base url
        // window.__env.baseUrl = '/';
      
        // Whether or not to enable debug mode
        // Setting this to false will disable console output
        // window.__env.enableDebug = true;    
    
  }(window));
import template from './wrapper-bottom.tpl.html';
const myController = function () {
    var vm = this;
};
const wrapperBottom = {
    bindings: { bottomRoutes: '<', activeDisplay: '<' },
    controllerAs: 'vm',
    controller: myController,
    template
};

export default wrapperBottom;
import 'ng-file-upload';

import notesService from './notes/notes.service';
import notes from './notes/notes.component';

import filesService from './files/files.service';
import files from './files/files.component';

import eventsService from './events/events.service';
import events from './events/events.component';


import sitesService from './sites/sites.service';
import sites from './sites/sites.component';


angular.module('invoicer.case-manager', ['ngFileUpload'])
.service('notesService', notesService)
.component('notes', notes)
.service('filesService', filesService)
.component('files', files)
.service('eventsService', eventsService)
.component('events', events)
.service('sitesService', sitesService)
.component('sites', sites)



import template from './services.tpl';

const myController = ['servicesService', 'icToastService', function ( servicesService, icToastService) {
    var vm = this;
    vm.modules = [];
    vm.editID = '';
    vm.loading = true;
    vm.addServiceOpen = false;
    
    vm.editItem = function(item){
        vm.editID = item.selected_service_id;
    }

    vm.hasBadChar = function(text) {
        if(!text) return false;
        // return(text.match(/<[^>]/gm) ? true : false)
    }

    vm.save = function(item){
      if (
        item.service_id == 46 &&
        item.parent_selected_service_id == null &&
        item.quantity != 1
      ) {
        item.quantity = 1;
      }
      
      item.created_at = null;
      item.updated_at = null;

      if (vm.hasBadChar(item.service_name)) {
        icToastService.showSimpleToast(
          "Service name contains an unsupported character",
          true
        );
        return false;
      }
      if (item.price < item.passthrough_revenue) {
        icToastService.showSimpleToast(
          "Passthrough cannot be greater than the item price"
        );
        return false;
      }
      if (item.price < item.to_contract) {
        icToastService.showSimpleToast(
          "Contract allotment cannot be greater than the item price"
        );
        return false;
      }
      servicesService.api
        .update(vm.invoiceId, item)
        .then(function (data) {
          vm.editID = "";
          icToastService.showSimpleToast("Service successfully updated");
        })
        .catch(function (err) {
          icToastService.showSimpleToast(err, true);
        });
    }

    vm.addService = function(item){
        if(vm.hasBadChar(item.service_name)) {
            icToastService.showSimpleToast('Service name contains an unsupported character');
            return false;                    
        }                
        servicesService.api.add(vm.invoiceId, item).then(function(data){
            vm.modules = data;
            vm.addServiceOpen = !vm.addServiceOpen                    
            icToastService.showSimpleToast('Service successfully added');
        })
        .catch(function(err) {
            icToastService.showSimpleToast(err, true);
        })                             
    }

    vm.deleteService = function(item){                
        vm.modules = vm.modules.filter(x => x.selected_service_id !== item.selected_service_id);
        servicesService.api.delete(vm.invoiceId, item).then(function(data){
            icToastService.showSimpleToast('Service successfully deleted');
        })
        .catch(function(err) {
            vm.modules = vm.modules.push(item);
            icToastService.showSimpleToast(err, true);
        })                             
    }            
    
    vm.cancel = function( ){
        vm.editID = '';
        //vm.loadServices();
    }            
        
    vm.loadServices = function () {
        servicesService.api.get(vm.invoiceId).then(function(data){
            vm.modules = data;                    
            vm.loading = false;
        })
        .catch(function(err) {
            icToastService.showSimpleToast(err, true);
        });                
    }               


    vm.$onInit = function(){
        vm.loadServices();          
    }
}];

export default {
    bindings: { invoiceId: '<', invoice: '<'},
    controllerAs: 'vm',
    controller: myController,
    template
}
          
    
    
    

const invoiceListState = function() {
    var myService = this;
    myService = {
        changes: 1,
        logChange: function set(count) {
            this.changes++;
        },
        trackChange: function get() {
            return this.changes;
        },
        display: {
            search: {
                active:'',
                set: function set(value) {
                    this.active = value;
                },
                get: function get( ) {
                    return this.active;
                }                    
                
            },
            orderBy: {
                field:  {
                    active: 'invoice_id',
                    set: function set(value) {
                        this.active = value;
                    },
                    get: function get( ) {
                        return this.active;
                    }                           
                },
                ascending:  {
                    active: true,
                    set: function set(value) {
                        this.active = value;
                    },
                    get: function get( ) {
                        return this.active;
                    }                           
                }
            }
        }
    }
    return myService;          
}

export default invoiceListState;
angular
  .module("invoicer.api.service", [])

  .service("invoicesService", [
    "$rootScope",
    "$http",
    "$q",
    "$filter",
    function ($rootScope, $http, $q, $filter) {
      var myService = this;
      var cachedInvoiceIds = [];
      var lastInvoiceID = null;
      myService = {
        invoice_cache: [],
        _feedMe: function (status) {
          const itemCount =
            status === "open"
              ? this.invoice_cache.filter((x) => x.invoice_level > 0).length
              : this.invoice_cache.filter((x) => x.invoice_level < 0).length;
          // Need to detect if the only invoice is the last visited because it could mean that the page was refreshed or was the entry point
          // in which case not all invoices will be available
          const loneWolf =
            itemCount > 1
              ? 0
              : this.invoice_cache.filter(
                  (x) => x.invoice_id === this.lastInvoiceID
                )
              ? 1
              : 0;

          if (itemCount && !loneWolf) {
            return $q.when(this.invoice_cache).then(function (invoices) {
              return invoices;
            });
          } else {
            return $http({
              method: "get",
              url: `${$rootScope.api}/api/admin/invoices/${status}`,
            }).then(
              function (res) {
                //add new invoices to invoice_cache and
                //merge to service state
                if (status === "closed") {
                  /**
                   * remove exisitng closed invoices and
                   * merge reloaded closed invoice from API
                   */
                  angular.merge(myService.invoice_cache, [
                    ...myService.invoice_cache.filter(
                      (x) => x.invoice_level > 0
                    ),
                    ...res.data,
                  ]);
                } else {
                  /**
                   * remove exisitng open invoices and
                   * merge reloaded open invoice from API
                   */
                  angular.merge(myService.invoice_cache, [
                    ...myService.invoice_cache.filter(
                      (x) => x.invoice_level < 0
                    ),
                    ...res.data,
                  ]);
                }

                return myService.invoice_cache;
              },
              function (err) {
                console.log("Error retrieving invoices");
              }
            );
          }
        },
        list: function (stage, status) {
          return this._feedMe(status).then(function (invoices) {
            invoices.filter((x) => x.invoice_level < 0).length;

            if (stage === 0) {
              if (status === "open") {
                return invoices.filter((x) => x.invoice_level > stage);
              } else {
                return invoices.filter((x) => x.invoice_level < stage);
              }
            } else {
              return invoices.filter((x) => x.invoice_level === stage);
            }
          });
        },
        getAll: function () {
          return myService.invoice_cache;
        },
        getOne: function getOne(invoiceId, from) {
          /**check if called */
          var self = this;
          this.lastInvoiceID = invoiceId;
          if (this.invoice_cache.length > 0) {
            var cachedInvoice = this.invoice_cache.find(
              (x) => x.invoice_id === +invoiceId
            );

            if (cachedInvoice) {
              return $q.when(cachedInvoice).then(function (resp) {
                return resp;
              });
            }
          }
          const myRequest = $http.get(
            `${$rootScope.api}/api/admin/invoice/get/${invoiceId}`
          );
          return myRequest
            .then(function (resp) {
              self.invoice_cache = self.invoice_cache
                .filter((x) => x.invoice_id !== resp.data.invoice_id)
                .concat(resp.data);
              return resp.data;
            })
            .catch(function (err) {
              return "Error retrieving invoice";
            });
        },
        paymentOptions: function paymentOptions(invoiceId) {
          return $http
            .get(
              `${$rootScope.api}/api/admin/options/paymentMethods/${invoiceId}`
            )
            .then(function (resp) {
              return resp.data;
            })
            .catch(function (err) {
              return "Error retrieving payment options";
            });
        },
        updateDetails: function updateDetails(invoice) {
          /**recalculate fee_total in case of License term change */
          if (!invoice.discount) {
            invoice.discount = 0;
          }
          /** */
          var self = this;
          return $http({
            method: "put",
            url: `${$rootScope.api}/api/admin/invoice/details/${invoice.invoice_id}`,
            data: invoice,
          })
            .then(function (resp) {
              self.refreshOne(resp.data);
              return resp.data;
            })
            .catch(function (err) {
              throw "Error updating invoice details";
            });
        },
        refreshOne: function refreshOne(invoice) {
          let tempInvoice = this.getOne(invoice.invoice_id).then((data) => {
            data.invoice_level = invoice.invoice_level;
            data.invoice_state = invoice.invoice_state;

            //updating invoice_cache with updated invoice
            this.invoice_cache = this.invoice_cache
              .filter((x) => x.invoice_id !== invoice.invoice_id)
              .concat(data);
          });
        },
      };

      return myService;
    },
  ])

  .service("statusOptionsService", [
    "$rootScope",
    "$http",
    function ($rootScope, $http) {
      const request = $http({
        method: "get",
        url: `${$rootScope.api}/api/admin/options/statuses`,
      });
      const options = request.then(function (resp) {
        return resp.data;
      });

      var factory = {};
      factory.getOptions = function () {
        return options.then(
          function (resp) {
            return resp;
          },
          function (err) {
            console.log("Error retrieving status options");
          }
        );
      };

      return factory;
    },
  ])
  .service("paymentOptionsService", [
    "$rootScope",
    "$http",
    "$q",
    "$filter",
    function ($rootScope, $http, $q, $filter) {
      var factory = {};
      factory.getOptions = function (invoice_id) {
        const request = $http({
          method: "get",
          url: `${$rootScope.api}/api/admin/options/paymentMethods/${invoice_id}`,
        });
        return request.then(
          function (resp) {
            return resp.data;
          },
          function (err) {
            console.log("Error retrieving payment options");
          }
        );
      };

      return factory;
    },
  ])
  .service("crudService", [
    "$http",
    "$q",
    function ($http, $q) {
      this.processForm = function (myData) {
        var request = $http({
          method: "post",
          url: "/api/api.invoices.php",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          //data: $.param(myData)
          data: myData,
        });
        return request;
        //return ( request.then(handleSuccess, handleError) );
      };

      function handleError(response) {
        // The API response from the server should be returned in a
        // nomralized format. However, if the request was not handled by the
        // server (or what not handles properly - ex. server error), then we
        // may have to normalize it on our end, as best we can.
        if (!angular.isObject(response.data) || !response.data.message) {
          return $q.reject("An unknown error occurred.");
        }

        // Otherwise, use expected error message.
        return $q.reject(response.data.message);
      }

      // Transform the successful response, unwrapping the application data
      // from the API response payload.
      function handleSuccess(response) {
        // if(!response.data.auth) {
        //     $rootScope.$broadcast('AUTH', {
        //         status: false
        //     });
        // }

        return response.data;
      }
    },
  ])

  .service("orderFormsService", [
    "$http",
    "$q",
    "utils",
    "$rootScope",
    function ($http, $q, utils, $rootScope) {
      this.getOrderForms = function () {
        var defer = $q.defer();

        $http({
          method: "get",
          url: `${$rootScope.api}/api/admin/forms`,
        }).then(
          function successCallback(response) {
            defer.resolve(response.data);
          },
          function errorCallback(error) {
            defer.reject(error);
          }
        );
        //     .success(function (resp) {

        //         if(!resp.auth) {
        //             $rootScope.$broadcast('AUTH', {
        //                 status: false
        //             });
        //         }

        //         defer.resolve(resp);
        //     })
        //     .error(function (error, status) {
        //         defer.reject(error);
        //     });

        return defer.promise;
      };
      return this;
    },
  ])

  .service("userService", [
    "$http",
    "$q",
    "$rootScope",
    function ($http, $q, $rootScope) {
      this.profile = {};

      this.getProfile = function () {
        var defer = $q.defer();

        $http({
          method: "get",
          url: `${$rootScope.api}/auth/account`,
          // headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`},
        }).then(
          function successCallback(response) {
            defer.resolve(response);
          },
          function errorCallback(response) {
            defer.reject(error);
          }
        );
        // .success(function (resp) {

        //     defer.resolve(resp);

        // })
        // .error(function (error, status) {
        //     defer.reject(error);
        // });

        return defer.promise;
      };

      return this;
    },
  ])

  .service("logout", [
    "$http",
    function ($http) {
      this.logoutUser = function () {
        console.log("logout not implemented");
      };
    },
  ]);

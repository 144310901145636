
const servicesService = ['$rootScope', '$http',
    function ($rootScope, $http) {

        var myService = this;


        myService.api = {
            formServices: function formServices(url_key) {
                return $http({
                    method: "get",
                    url: `${$rootScope.api}/api/admin/invoice/form-services/${url_key}`
                }).then(function (resp) {                    
                    return resp.data;
                }).catch(function(err) {
                    return 'Error retrieving form services';
                });
            },
            templateServices: function templateServices(template_id) {
                return $http({
                    method: "get",
                    url: `${$rootScope.api}/api/admin/template/services/${template_id}`
                }).then(function (resp) {                    
                    return resp.data;
                }).catch(function(err) {
                    return 'Error retrieving form services';
                });
            },                    
            blankService: function blankService(url_key) {
                return $http({
                    method: "get",
                    url: `${$rootScope.api}/api/admin/invoice/blank-service`
                }).then(function (resp) {                    
                    return resp.data;
                }).catch(function(err) {
                    return 'Error retrieving blank service';
                });
            },                    
            get: function get(invoiceId) {
                return $http({
                    method: "get",
                    url: `${$rootScope.api}/api/admin/invoice/services/${invoiceId}`
                }).then(function (resp) {                    
                    return resp.data;
                }).catch(function(err) {
                    return 'Error retrieving services';
                });
            },
            update: function set(invoiceId, service) {
                return $http({
                    method: "put",
                    url: `${$rootScope.api}/api/admin/invoice/service/${invoiceId}`,
                    data: service
                }).then(function (resp) {                            
                    return resp.data;
                }).catch(function(err) {
                    return 'Error updating service';
                });
            },
            add: function set(invoiceId, service) {
                return $http({
                    method: "post",
                    url: `${$rootScope.api}/api/admin/invoice/service/${invoiceId}`,
                    data: service
                }).then(function (resp) {                            
                    return resp.data;
                }).catch(function(err) {
                    return 'Error adding service';
                });
            },
            delete: function set(invoiceId, service) {
                return $http({
                    method: "delete",
                    url: `${$rootScope.api}/api/admin/invoice/service/${invoiceId}/${service.selected_service_id}`,
                }).then(function (resp) {                            
                    return resp.data;
                }).catch(function(err) {
                    return 'Error deleting service';
                });
            }                       
        };
        return myService;
    }];

    export default servicesService;
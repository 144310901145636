import activeState from './nav.service';
import navCenter from './nav-center/nav-center.directive';
import navLeft from './nav-left/nav-left.component';
import navRight from './nav-right/nav-right.directive';
import navQueue from './nav-queue/nav-queue.component';
import wrapperBottom from './wrapper-bottom/wrapper-bottom.component';


angular.module('invoicer.nav', [])
.factory('activeState', activeState)
.directive('navCenter', navCenter)
.component('navLeft', navLeft)
.directive('navRight', navRight)
.component('navQueue', navQueue)
.component('wrapperBottom', wrapperBottom)
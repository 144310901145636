import template from './invoice-id-edit.tpl.html';

const invoiceIdEdit = [ function ( ) {

        var myLink = function (scope, element, attrs) {
                    
        };

        return {
            restrict: "E",
            link: myLink,
            scope: {
                invoice: '='            
            },
            template
        };
    }];

export default invoiceIdEdit


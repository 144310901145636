const totals = [
  function () {
    // MODULES AND EXTENSIONS - EXPECTS MODULE
    this.getModulePriceArray = function (myModule) {
      var extensionsBasePrice = 0; // no quantity factored in
      var extensionsPrice = 0;
      var extensionsPricePassthrough = 0;

      var extensionsPriceLicensing = 0; // no quantity factored in
      var extensionsPriceServices = 0; // no quantity factored in
      var internalExtensionPrice = 0;

      var applyToContract = 0;

      if (myModule.extensions !== undefined) {
        angular.forEach(myModule.extensions, function (value, key) {
          if (value.quantity) {
            // cast the price
            extensionsBasePrice += +value.price;

            // apply the quantity into the base measurements
            extensionsPrice += +value.price * +value.quantity;
            extensionsPricePassthrough +=
              +value.passthrough_revenue * +value.quantity;

            // if a portion of an extension should be applied to a state contract, capture it here
            applyToContract += +value.to_contract;

            // licensing and services need to be calculated in scope, and then added to the aggregate value

            // first, determine the internal price (after passthrough is applied
            var localExtensionsPrice = +value.price * +value.quantity;
            var localExtensionsPricePassthrough =
              +value.passthrough_revenue * +value.quantity;
            var localInternalExtensionPrice =
              localExtensionsPrice - localExtensionsPricePassthrough;

            internalExtensionPrice += localInternalExtensionPrice;

            // Now calculate how much should go to licensing vs services
            var localExtensionsPriceLicensing =
              localInternalExtensionPrice * +value.licensing_indirect;
            var localExtensionsPriceLServices =
              localInternalExtensionPrice - localExtensionsPriceLicensing;

            // Now add the calculations to the aggregrate numbers
            extensionsPriceLicensing += localExtensionsPriceLicensing;
            extensionsPriceServices += localExtensionsPriceLServices;
          }

          /*
                    var logData = value.service + ': Price: ' + value.price;
                    logData += "\n" + value.service + ': Passthrough: ' + extensionsPricePassthrough;
                    logData += "\n" + value.service + ': Internal: ' +  internalExtensionPrice;
                    logData += "\n" + value.service + ': Licensing: ' + extensionsPriceLicensing;
                    logData += "\n" + value.service + ': Services: ' + extensionsPriceServices; 
                    $log.info(logData);
                    */
        });
      }

      var modulePrice = +myModule.price * myModule.quantity;
      var modulePricePassthrough =
        myModule.passthrough_revenue * myModule.quantity;

      applyToContract += +myModule.to_contract;

      var fullModulePassthrough =
        modulePricePassthrough + extensionsPricePassthrough;

      var internalModulePrice = modulePrice - modulePricePassthrough;

      var modulePriceLicensing =
        internalModulePrice * +myModule.licensing_indirect;
      var modulePriceServices =
        internalModulePrice -
        internalModulePrice * +myModule.licensing_indirect;

      var fullModuleTotal = modulePrice + extensionsPrice;
      var fullModuleLicensingTotal =
        modulePriceLicensing + extensionsPriceLicensing;
      var fullModuleServicesTotal =
        modulePriceServices + extensionsPriceServices;

      var response = {
        fullModuleTotal: fullModuleTotal,
        fullModuleLicensingTotal: fullModuleLicensingTotal,
        fullModuleServicesTotal: fullModuleServicesTotal,
        fullModulePassthrough: fullModulePassthrough,
        modulePrice: modulePrice,
        modulePriceLicensing: modulePriceLicensing,
        extensionsPrice: extensionsPrice,
        extensionsBasePrice: extensionsBasePrice,
        extensionsPriceLicensing: extensionsPriceLicensing,
        applyToContract: applyToContract,
      };

      return response;
      //return [fullModuleTotal, fullModuleLicensingTotal, modulePrice, modulePriceLicensing, extensionsPrice, extensionsPriceLicensing, fullModulePassthrough];
    };

    this.getModuleExtensionsPrice = function (myModule) {
      // RETURNS YEARLY PRICE OF MODULE EXTENSIONS * QUANTITY, BUT NOT TERM
      var myTotals = this.getModulePriceArray(myModule);
      return myTotals.extensionsPrice;
    };
    this.getModuleExtensionsBasePrice = function (myModule) {
      // RETURNS YEARLY PRICE OF MODULE EXTENSIONS * QUANTITY, BUT NO TERM
      var myTotals = this.getModulePriceArray(myModule);
      return myTotals.extensionsBasePrice;
    };

    this.getModulePrice = function (myModule) {
      // RETURNS YEARLY PRICE OF MODULE PLUS SELECTED EXTENSIONS * QUANTITY, BUT NO TERM
      var myTotals = this.getModulePriceArray(myModule);
      return myTotals.fullModuleTotal;
    };

    // SUBTOTALS - EXPECTS INVOICE
    this.getTotals = function (invoice) {
      // WRAPPER THAT RETURNS THE TOTALS BROKEN DOWN BY TOTAL, SUBTOTAL AND LICENSING/SERVICES/PASSTHROUGH

      var subTotal = 0;
      var discount = 0;

      //var discount = +invoice.discount === 'undefined' ? +invoice.discount : 0;
      var discount = +invoice.discount === "undefined" ? 0 : +invoice.discount;
      var subTotalLicensing = 0;
      var subTotalServices = 0;
      var subTotalPassthrough = 0;

      var applyToContract = 0; // indicates the amount that should be applied to another contract, does not impact calculations

      var licensing = 0;
      var services = 0;
      var internal_price = 0;

      var licensing_proportion;
      var services_proportion;

      var preDiscount_licensing;
      var preDiscount_services;
      var preDiscount_internalPrice;

      var parentService = this;
      if (invoice.modules !== undefined) {
        angular.forEach(invoice.modules, function (value, key) {
          //if(value.quantity) {
          var myTotals = parentService.getModulePriceArray(value);
          subTotal += myTotals.fullModuleTotal;
          subTotalLicensing += myTotals.fullModuleLicensingTotal;
          subTotalServices += myTotals.fullModuleServicesTotal;
          subTotalPassthrough += myTotals.fullModulePassthrough;
          applyToContract += +myTotals.applyToContract;

          //}
        });
      }

      licensing = subTotalLicensing * +invoice.license_term;
      services = subTotalServices * +invoice.license_term;
      internal_price = licensing + services;

      preDiscount_licensing = licensing;
      preDiscount_services = services;
      preDiscount_internalPrice = internal_price;
      licensing_proportion = licensing / internal_price;
      services_proportion = services / internal_price;

      // If there is a discount, then remove it from licensing and services according to the licencing percentage of the contract

      if (discount > 0) {
        licensing -= +licensing_proportion * discount;
        services -= +services_proportion * discount;
        internal_price -= discount;
      }

      var myReturn = {
        subTotal: subTotal,
        total: subTotal * +invoice.license_term - discount,

        internal_price: internal_price,
        preDiscount_internalPrice: preDiscount_internalPrice,

        subTotalLicensing: subTotalLicensing,
        licensing: licensing,
        preDiscount_licensing: preDiscount_licensing,
        licensing_proportion: licensing_proportion,

        subTotalServices: subTotalServices,
        services: services,
        preDiscount_services: preDiscount_services,
        services_proportion: services_proportion,

        subTotalPassthrough: subTotalPassthrough,
        passthrough: subTotalPassthrough * +invoice.license_term,
        applyToContract: applyToContract,
        discount: discount,
      };

      //$log.info(myReturn);
      return myReturn;
    };

    this.getTotal = function (myModule) {
      var myTotals = this.getTotals(myModule);
      return myTotals.total;
    };

    this.getSubTotal = function (myInvoice) {
      var myTotals = this.getTotals(myInvoice);
      return myTotals.subTotal;
    };
    this.getLicensingTotal = function (myInvoice) {
      var myTotals = this.getTotals(myInvoice);
      return myTotals.licensing;
    };
    this.getServicesTotal = function (myInvoice) {
      var myTotals = this.getTotals(myInvoice);
      return myTotals.services;
    };
    this.getPassthroughTotal = function (myInvoice) {
      var myTotals = this.getTotals(myInvoice);
      return myTotals.passthrough;
    };
    this.getApplyToContract = function (myInvoice) {
      var myTotals = this.getTotals(myInvoice);
      return +myTotals.applyToContract;
    };
    this.getLicenseTerm = function (myInvoice) {
      return +myInvoice.license_term;
    };

    this.getDiscount = function (myInvoice) {
      return +myInvoice.discount;
    };
  },
];

export default totals;

import invoiceNavTemplate from './partials/invoice-nav';

import templateDetailWrapper from './partials/detail-wrapper.html';
import templateDetailWrapperTop from './partials/detail-wrapper-top.html';
import templateBilling from './partials/billing.html';
import templateRequestor from './partials/requestor.html'



const router = [ '$stateProvider', '$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider) {

      /////////////////////////////
      // Redirects and Otherwise //
      /////////////////////////////
    //   $locationProvider.html5Mode(true);
      // Use $urlRouterProvider to configure any redirects (when) and invalid urls (otherwise).
      $urlRouterProvider
        // If the url is ever invalid, e.g. '/asdf', then redirect to '/' aka the home state
        .otherwise('/stage/open/1');
       // .otherwise('/login');

      $stateProvider
        
      //////////////
      // Invoices //
      //////////////
      .state('invoices', {
        abstract: true,
        url: '',
        resolve: {
                profile: ['loginService',
                    function( loginService){                      
                      return loginService.account.getProfile();
                }],                
            },      
        template: invoiceNavTemplate,
        controller: ['$scope', '$stateParams', '$state', 'activeState', 'profile', 'Authorization', function($scope, $stateParams, $state, activeState, profile, Authorization) {
        }]
      })

      .state('invoices.list', {
        url: '/stage/:status/:stage',
        component: 'invoiceDashboard',
        resolve: {
          status: ['$stateParams', 'activeState', function($stateParams, activeState) {                
            activeState.setStatus($stateParams.status);
            return $stateParams.status;
          }],
          stage: ['$stateParams', 'activeState', function($stateParams, activeState) {
            activeState.setStage($stateParams.stage);
            return +$stateParams.stage;
          }]
        }            
      })

       
      ///////////////////////
        // Invoices > Detail //
        ///////////////////////
        .state('invoices.detail', {
         abstract: true,
          url: '/invoiceId/:invoiceId',
          resolve: {
                invoiceId: ['$stateParams', 'activeState',
                    function($stateParams, activeState){
                    activeState.setInvoiceId($stateParams.invoiceId);
                    return $stateParams.invoiceId;
                }],
                activeDisplay: ['$stateParams', 'activeState',
                    function($stateParams, activeState){
                    activeState.setDisplay($stateParams.display);
                    return $stateParams.display;
                }],                               
                bottomRoutes: 
                    function( ){                    
                        var baseUiSref = 'invoices.detail.display';
                    return [
                        {route: 'services', display: 'Services', uiSref: `${baseUiSref}({ display: 'services'})` },
                        {route: 'invoice', display: 'Sites', uiSref: `${baseUiSref}({ display: 'sites'})` },
                        {route: 'files', display: 'Files', uiSref: `${baseUiSref}({ display: 'files'})` },
                        {route: 'notes', display: 'Notes', uiSref: `${baseUiSref}({ display: 'notes'})` },
                        {route: 'events', display: 'Event Log', uiSref: `${baseUiSref}({ display: 'events'})` },
                    ]
                },                    
                invoice: ['invoicesService', '$stateParams',
                    function( invoicesService, $stateParams){                        
                    return invoicesService.getOne($stateParams.invoiceId);
                }],                                                                     
            },
            views: {
	              '': {
	                   template: templateDetailWrapper,
    	               controller: ['$scope', 'invoice', 'invoiceId', 'bottomRoutes', 'activeDisplay', function (  $scope, invoice, invoiceId, bottomRoutes, activeDisplay) {                           
                                $scope.invoice = invoice;
                                $scope.invoiceId = +invoiceId;
                                $scope.bottomRoutes = bottomRoutes;
                                $scope.activeDisplay = activeDisplay;
    	               }]
	              },
                    'one-click@invoices.detail': {
	                    template: '<one-click invoice-id="invoiceId" invoice="invoice"></one-click>'
	              },                  
	  	            'detail-top@invoices.detail': {
	  	                template: templateDetailWrapperTop                                          
	              },
	    	        'detail-bottom@invoices.detail': {
                        template: '<wrapper-bottom bottom-routes="bottomRoutes" active-display="activeDisplay"></wrapper-bottom>'
	              },
	 	            'invoiceTerms@invoices.detail': {
                        template: '<invoice-terms invoice-id="invoiceId" invoice="invoice"></invoice-terms>'
	              },	             
		            'billing@invoices.detail': {
		               template: templateBilling
	              },
		            'requestor@invoices.detail': {
		               template: templateRequestor
	              }
           }          
        })

         .state('invoices.detail.display', {
         url: '/:display',
         resolve: {          
            invoice: ['invoicesService', '$stateParams',
                function( invoicesService, $stateParams){                        
                return invoicesService.getOne($stateParams.invoiceId);
            }]
        },
         template:
          function ($stateParams) {
                // const myBad = `<${$stateParams.display} invoice="invoice" invoice-id=${invoice.invoice_id}"></${$stateParams.display}>`;
                // console.log(myBad)
                 return '<' + $stateParams.display + ' invoice="invoice" invoice-id="invoiceId"></' + $stateParams.display + '>';
         },                  
          controller: ['$scope', 'invoiceId', 'invoice', function($scope, invoiceId, invoice) {
                $scope.invoice = invoice;
                $scope.invoiceId = invoiceId;
            }]         	     
	     })
         .state('invoices.detail.display.editTop', {
         url: '/:editItem/edit',
         resolve: {          
            invoice: ['invoicesService', '$stateParams',
                function( invoicesService, $stateParams){                     
                return invoicesService.getOne($stateParams.invoiceId);
            }]
        },         
          views: {
            'detail-top@invoices.detail': {
                template:
                    function ($stateParams){                        
                        return '<edit-wrapper invoice-id="invoiceId" invoice="invoice">' + 
                                    '<' + $stateParams.editItem + '-edit invoice-id="invoiceId" invoice="invoice"></' + $stateParams.editItem + '-edit>' +
                                '</edit-wrapper>';                                         
                },                  
                controller: ['$scope', 'invoiceId', 'invoice', function($scope, invoiceId, invoice) {
                        $scope.invoice = invoice;
                        $scope.invoiceId = invoiceId;
                    }] 
            }
          }        	     
	     })                      
    ;}
  ];

export default router;
import template from "./nav-right.tpl.html";

const navRight = [
  "orderFormsService",
  "loginService",
  "activeState",
  "invoicesService",
  "$rootScope",
  function (
    orderFormsService,
    loginService,
    activeState,
    invoicesService,
    rootScope
  ) {
    var myLink = function (scope, element, attrs) {
      loginService.account.getProfile().then(function (data) {
        if (typeof data !== "undefined") {
          scope.fullName = data.name;
          scope.userName = data.username;
        }
      });

      orderFormsService.getOrderForms().then(
        function (data) {
          scope.orderForms = data.filter((x) => x.active === 1);
        },
        function (err) {
          console.log("Could not retrieve order forms");
        }
      );

      scope.logout = function () {
        loginService.account.logout();
      };

      // Export Options
      var state = activeState.getState("open");
      scope.invoices = invoicesService.getAll();
      scope.exportExcel = function () {
        return scope.invoices;
      };

      scope.openReporting = function () {
        var url = "https://reporting.orders.intocareers.net/";
        window.open(url);
      };

      // Export Options
      scope.openLink = function (link) {
        var Url = window.location.protocol + "/" + link;
        window.open(Url);
      };

      // Order Forms
      scope.openForm = function (item) {
        // var formUrl = 'https://orders.intocareers.net/' + item.key;
        const formUrl = `${rootScope.orderUrl}/${item.key}`;
        window.open(formUrl);
      };
    };

    return {
      restrict: "E",
      link: myLink,
      scope: {},
      template,
    };
  },
];

export default navRight;

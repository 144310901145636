import template from './service-item.tpl';

const serviceItem = ['$mdToast', function ($mdToast) {
    var myLink = function (scope, element, attrs) {
            
    };        

    return {
        restrict: "E",
        link: myLink,
        scope: {
            item: '=',
            edit: '&'     
        },
        template
    };
}];

export default serviceItem;

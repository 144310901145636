import template from './services-add.tpl';
const myController = ['servicesService', function (servicesService) {
    var vm = this;

    vm.templateLoading = true;
    vm.servicesLoading = true;
    vm.formServices = [];
    vm.templateServices = [];
        
    vm.parentService = {};
    vm.oneAtATime = true;




    vm.$onInit = function () {
        servicesService.api.formServices(vm.urlKey).then(function(data){                                     
            vm.formServices = data;
            vm.servicesLoading = false;
        });
        servicesService.api.templateServices(vm.templateId).then(function(data){                                     
            vm.templateServices = data;
            vm.templateLoading = false;
        });        
        
    }
    vm.addExtension = function(parentService, extension) {
        var newService = Object.assign(
            extension,
            {
                quantity: 1,
                parent_selected_service_id: parentService.selected_service_id,
                price: extension.price || extension.list_price,
                service_name: extension.service_name || extension.service
            }
        );
        vm.save(newService);
    }
    vm.addParent = function(parentService) {
        vm.save(parentService);
    }        

}];

const addService = {
            bindings: { 
                services: '<', 
                urlKey: '<', 
                templateId: '<', 
                save: '<'
            },
            controllerAs: 'vm',
            controller: myController,
            template
        };
export default addService;


const icToastService = ['$mdToast', function ( $mdToast ) {
    const showSimpleToast = (title, isError) => {
        
        const message = Array.isArray(title) ? title.join("<br />") : title;
        const toastColor = isError ? 'md-warn' : 'md-hue';
        $mdToast.show(
            $mdToast.simple()
                .textContent(message)
                .actionKey('z')
                .hideDelay(2000)
                .position('bottom right')
                .action('OK')
                .highlightAction(true)
                .highlightClass(toastColor)
            );
    }

    return { showSimpleToast };
}];
export default icToastService;
 

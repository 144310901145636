
const notesService = ['$rootScope', '$http',
                        function ($rootScope, $http) {
        var myService = this;        
        myService.api = {
            add: function post(invoiceId, note) {
                if(typeof(note) === 'undefined') { note = '';}
                return $http( {
                          method: "post",
                          url: `${$rootScope.api}/api/admin/invoice/note/${invoiceId}`,       
                          data:  { note } 
                    }).then(function (resp) {
                        return resp.data;
                    }).catch(function(err) {
                        throw("Error adding note");
                    });                    
            },
            get: function get(invoiceId) {
                return $http( {
                        method: "get",
                        url: `${$rootScope.api}/api/admin/invoice/notes/${invoiceId}`,
                    }).then(function (resp) {                              
                        return resp.data;                              
                    }).catch(function(err) {
                        throw("Error retrieving notes");
                    });
            },        
            update: function set( invoiceId, note ) {
                return $http( {
                        method: "put",
                        url: `${$rootScope.api}/api/admin/invoice/note/${invoiceId}/${note.note_id}`,
                        data: note
                    }).then(function (resp) {
                        return resp;                          
                    }).catch(function(err) {
                        throw("Error updating note");
                    });                    
            },       
            delete: function set(invoiceId, note) {
                return $http( {
                        method: "delete",
                        url: `${$rootScope.api}/api/admin/invoice/note/${invoiceId}/${note.note_id}`,
                     }).then(function (resp) {
                        return resp;                          
                     }).catch(function(err) {                     
                        throw("Error deleting note");
                     });    
            }                                                            
        };    
        
        
        return myService;
    }];

export default notesService;



const sitesService = ['$rootScope', '$http', 
             function ($rootScope, $http) {
    
    var myService = this;
    
    myService.api = {

        get: function get(invoiceId) {
            return $http( {
                method: "get",
                url: `${$rootScope.api}/api/admin/invoice/sites/get/${invoiceId}`,                        
                    }).then(function (resp) { 
                    return resp.data;                     
                })
        },        
        update: function set( invoiceId, site ) {                
            return $http( {
                    method: "post",
                    url: `${$rootScope.api}/api/admin/invoice/sites/update/${invoiceId}`,
                    data: {action:"updateSite", filters: {invoiceId: invoiceId}, data: site  }
                    }).then(function (resp) {      
                        return resp.data;                              
                })
        }                                                           
    };        
    
    return myService;
}]
export default sitesService;

const  clickFilter = function () {
    // Necessary since the built in filter treats negative and positive integers the same
    return function (input, level, display) {
        if (!Array.isArray(input)) {
            return [];
        };
        return input.filter(function (el) { return el.display === display && el.level === level });
    }
};

export default clickFilter;
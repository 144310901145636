import template from './events.tpl.html';

const myController = [ 'eventsService', function ( eventsService) {
    const vm = this;
    vm.loading = true;
    vm.$onInit = function () {
        eventsService.api.get(vm.invoiceId).then(function(data){                     
            vm.events = data;                
            vm.loading = false;
        });

    }
}];

const events = {
    bindings: { invoiceId: '<' },
    controllerAs: 'vm',
    controller: myController,
    template
};

export default events;
